<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="addPassengerMain">
      <iHeader
        backType="blank"
        :backEvent="true"
        @back-event="addPassengerback()"
        :back="true"
        svgColor="'#13161B"
      >
    <div slot="headText">
        <span class="head-txt">
          新增实际乘车人
        </span>
    </div>
    </iHeader>
      <!-- <div class="publicHead">
        <p>新增实际乘车人</p>
      </div> -->
      <div class="addPassengerbox">
        <template v-if="['msd'].includes($cookies.get('tenant'))">
          <div class="normalInputRow">
            <MInput
              title="乘车人"
              noCancel="false"
              must="true"
              v-model="formdata.passengers"
              pla="乘车人需要输入医生全名"
            ></MInput>
          </div>
          <div class="normalInputRow">
            <MInput
              title="乘车人医院"
              noCancel="false"
              must="true"
              v-model="formdata.department"
              pla="请输入乘车人医院"
            ></MInput>
          </div>
        </template>
        <template v-else>
          <div class="normalInputRow">
            <MInput
              title="实际乘车人"
              noCancel="false"
              v-model="formdata.passengers"
              pla="请输入姓名"
              must="true"
            ></MInput>
          </div>
          <div class="normalInputRow">
            <MInput
              title="医院"
              must="true"
              noCancel="false"
              v-model="formdata.organization"
              pla="请输入医院"
            ></MInput>
          </div>
          <div class="normalInputRow">
            <MInput
              title="科室"
              must="true"
              noCancel="false"
              v-model="formdata.department"
              pla="请输入科室"
            ></MInput>
          </div>
        </template>
      </div>
      <div class="btnBox">
        <a @click="addPassengerback()">取消</a>
        <a class="saveBox" @click="savePassenger()">保存</a>
      </div>
    </div>
  </mt-popup>
</template>

<script>
import { addPassenger as validator } from "@/views/controllers/carValidatorConfig";
export default {
  props: ["passengerName"],
  data() {
    return {
      isShowPopup: false,
      passengers: [],
      formdata: {}
    };
  },
  created() {
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners("addPassengerback"); //保存当前路由返回事件记录
        this.$root.$eventHub.$on("addPassengerback", (data) => {
          this.addPassengerback();
          this.$root.$eventHub.$off("addPassengerback");
        });
      } else {
        this.removeRouteListeners("addPassengerback"); //返回删除记录
      }
    },
  },
  mounted() {
    
  },
  methods: {
    addPassengerback() {
      this.isShowPopup = false;
    },
    showPopup(data) {
      this.formdata = data ? data : {};
      this.isShowPopup = true;
    },
    savePassenger() {
      var validateResult = this.validate(this.formdata, validator);
      if (!validateResult) return;
      this.$emit("getPassengerItem", this.formdata);
      this.addPassengerback();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/approval/approvalIndex.scss";
</style>