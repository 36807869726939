<template>
  <div>
    <iHeader
      backType="blank"
      :backEvent="true"
      @back-event="approvalPassengerback()"
      :back="true"
      svgColor="'#13161B"
    >
    <div slot="headText">
        <span class="head-txt">
          {{
          ["msd"].includes($cookies.get("tenant"))
            ? "实际乘车人"
            : "实际乘车人及备注"
        }}
        </span>
    </div>
  </iHeader>
    <!-- <div class="publicHead">
      <p>
        {{
          ["msd"].includes($cookies.get("tenant"))
            ? "实际乘车人"
            : "实际乘车人及备注"
        }}
      </p>
    </div> -->
    <div class="PassengerBox">
      <div>
        <ul v-if="passengerList.length > 0">
          <li v-for="(item, index) in passengerList" :key="index">
            <div class="leftBox">
              <p>实际乘车人:{{ item.passengers }}</p>
              <span v-if="!['msd'].includes($cookies.get('tenant'))"
                >医院:{{ item.organization }}</span
              >
              <span
                >{{
                  ["msd"].includes($cookies.get("tenant"))
                    ? "乘车人单位"
                    : "科室"
                }}:{{ item.department }}</span
              >
            </div>
            <div class="rgihtBox">
              <div @click="removePassenger(item, index)">删除</div>
              <div @click="goAddPassenger(item)">编辑</div>
            </div>
          </li>
        </ul>
        <NoData v-else></NoData>
      </div>
      <div
        class="descrBox"
        v-if="passengerList.length > 0 && !['msd'].includes($cookies.get('tenant')) ">
        <p>用车备注</p>
        <textarea placeholder="请输入用车备注" v-model="descr"></textarea>
      </div>
    </div>
    <a :class="['saveBtn', isloding && 'disablesaveBtn']" @click="!isloding && savePassenger()">{{
      SmartStorage.get("orderDetail") &&
      SmartStorage.get("orderDetail").IsOnline
        ? "保存并提交"
        : "保存"
    }}</a>
    <AddButton
      :color="$publicFun.setColor()"
      @click="goAddPassenger('')"
    ></AddButton>
    <addPassenger
      @getPassengerItem="getPassengerItem"
      ref="addPassenger"
    ></addPassenger>
  </div>
</template>

<script>
import filterEmoji from "@/views/controllers/filterEmoji.js";
import addPassenger from "@/views/common/addPassenger";
export default {
  name:'approvalPassenger',
  mixins: [filterEmoji],
  data() {
    return {
      isloding: false,
      passengerList: [],
      descr: "",
      flagEidt: false,
    };
  },
  created() {
    this.setRouteListeners("approvalPassengerback");
    this.$root.$eventHub.$on("approvalPassengerback", (data) => {
      this.approvalPassengerback();
      this.$root.$eventHub.$off("approvalPassengerback"); //解绑当前监听，否则下轮监听还会持续叠加
    });
    if (this.$route.query.passengersList.length > 0) {
      this.passengerList = this.$route.query.passengersList;
      this.descr = this.$route.query.passengersList[0].remark;
    }
  },
  components: {
    addPassenger,
  },
  methods: {
    approvalPassengerback() {
      this.removeRouteListeners("approvalPassengerback");
      this.$router.back();
    },
    removePassenger(data, index) {
      this.passengerList.splice(index, 1);
      if (this.passengerList.length < 1) {
        this.descr = "";
      }
    },
    goAddPassenger(data) {
      this.flagEidt = data ? true : false;
      this.$refs.addPassenger.showPopup(data);
    },
    getPassengerItem(data) {
      !this.flagEidt && this.passengerList.push(data);
    },
    async savePassenger() {
      if (this.passengerList.length < 1) {
        this.$iToast("请至少添加一个乘车人");
        return false;
      }
      let pasengerReg = /^((?!教授|老师|医生|主任).)*$/;
      if (
        !this.passengerList.every((item, index, arr) => {
          console.log(item.passengers);
          return pasengerReg.test(item.passengers);
        })
      ) {
        this.$iToast(
          "实际乘车联系人姓名中不能包含教授、老师、医生、主任等字或词汇,请进行修改"
        );
        return false;
      }
      this.passengerList.forEach((element) => {
        element.passengers = this.EmojiExchangeString(element.passengers);
        element.organization =
          element.organization &&
          this.EmojiExchangeString(element.organization);
        element.department = this.EmojiExchangeString(element.department);
      });
      let params = ["msd"].includes(this.$cookies.get("tenant"))
        ? {
            BusinessId: this.$route.query.businessId,
            Passengers: this.passengerList,
            IsOnline: false,
          }
        : {
            BusinessId: this.$route.query.businessId
              ? this.$route.query.businessId
              : this.SmartStorage.get("orderDetail").BusinessId,
            Passengers: this.passengerList,
            IsOnline:
              this.SmartStorage.get("orderDetail") &&
              this.SmartStorage.get("orderDetail").IsOnline,
            Remark: this.EmojiExchangeString(this.descr),
            TaskId: this.SmartStorage.get("TaskId") || null,
          };
      if (
        this.SmartStorage.get("orderDetail") &&
        this.SmartStorage.get("orderDetail").IsOnline
      ) {
        params.ActionId = this.SmartStorage.get("orderDetail").ActionId || null;
      }
      this.isloding = true;
      this.$iDelay(() => {
        this.services.SavePassenger(params).then((res) => {
          if (res.success) {
            let tip =
              this.SmartStorage.get("orderDetail") &&
              this.SmartStorage.get("orderDetail").IsOnline
                ? "提交成功！"
                : "保存成功！";
            this.$iToast(tip);
            this.SmartStorage.get("orderDetail") &&
            this.SmartStorage.get("orderDetail").IsOnline
              ? this.$router.push({
                  path: "/approvalList",
                  query: {
                    IsOnline:
                      this.SmartStorage.get("orderDetail") &&
                      this.SmartStorage.get("orderDetail").IsOnline,
                  },
                })
              : this.$router.back();
            this.isloding = false;
          }
        });
      }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/approval/approvalIndex.scss";
</style>