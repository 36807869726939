import * as tinyCookie from 'tiny-cookie';
import { SmartStorage } from 'smart-core-util';
let isAddMsdPassenger = ['msd'].includes(tinyCookie.get('tenant'));
console.log(isAddMsdPassenger);
export const additem = {
  dtStart: {
    required: true,
    description: '请选择用车日期'
  },
  carModus: {
    required: true,
    description: '请选择用车形式'
  },
  carType: {
    required: true,
    description: '请选择车型'
  },
  qty: {
    required: true,
    description: '请填写数量',
    regExp: /^\+?[1-9][0-9]*$/,
    regExpMsg: '请填写正确数量'
  },
  supplierName: {
    required: true,
    description: '请选择供应商'
  },
  price: {
    required: false,
    expression: function(m) {
      if (m.isSpecial == 0 && !m.price) return true;
    },
    expressionMsg: '尚未配置价格'
  },
  isSpecial: {
    required: false,
    expression: function(m) {
      if (m.isSpecial != 0 && (!m.itemData.destination || !m.itemData.departure)) return true;
    },
    expressionMsg: '请选择出发地跟目的地'
  }
};
export const platformOrder = {
  carRule: {
    required: true,
    description: '请选择用车形式'
  },
  departure_time: {
    required: false,
    expression: function(m) {
      if (m.carRule != 14 && !m.departure_time) return true;
    },
    expressionMsg: '请选择用车日期'
  },
  useDays: {
    required: false,
    expression: function(m) {
      if (m.carRule == 12 && !m.useDays) return true;
    },
    expressionMsg: '请选择包车天数'
  },
  start_name: {
    required: true,
    description: '请选择出发地'
  },
  end_name: {
    required: false,
    expression: function(m) {
      if (m.carRule != 12 && !m.end_name) return true;
    },
    expressionMsg: '请选择目的地'
  },
  flight_num: {
    required: false,
    expression: function(m) {
      if ((m.carRule == 7 || m.carRule == 8) && !m.flight_num) return true;
    },
    expressionMsg: '请填写航班号'
  },
  'passenger.name': {
    required: true,
    description: `请${tinyCookie.get('tenant') == 'roche' ? '选择' : '录入'}乘车联系人`,
    expression: function(m) {
      let name = m.passenger.name.replace(/[ ]/g, '');
      if (name.length == 0) return true;
    },
    expressionMsg: '乘车联系人不能为空'
  },
  passenger_phone: {
    required: true,
    description: '请填写联系电话',
    regExp: /^1\d{10}$/,
    regExpMsg: '请填写正确联系电话'
  },
  // 'qty': {
  //     required: true,
  //     description: '请填写实际用车人数',
  //     regExp: /^\+?[1-9][0-9]*$/,
  //     regExpMsg: '请填写实际用车人数'
  // },
  passenger_department: {
    required: false,
    expression: function(m) {
      if (tinyCookie.get('tenant') == 'msd') {
        if (!m.passenger_department) return true;
        let name = m.passenger_department.replace(/[ ]/g, '');
        if (name.length == 0) return true;
      }
    },
    expressionMsg: '请填写乘车人医院'
  },
  clat: {
    required: true,
    description: '请同意获取所在地位置'
  },
  desc: {
    expression: function(m) {
      console.log('mmmmmmmmmmmmmmmm', m);
      if (tinyCookie.get('tenant') == 'dsm' && SmartStorage.get('eventData').userEventType == 'approval' && !m.descr) return true;
    },
    expressionMsg: '请说明用车事由'
  }
};
export const addPassenger = isAddMsdPassenger
  ? {
      passengers: {
        required: true,
        description: '请填写乘车人姓名',
        regExp: /^((?!教授|老师|医生|主任).)*$/,
        regExpMsg: '乘车人姓名中不能包含“教授、老师、医生、主任”等，请录入乘车人全名'
      },
      department: {
        required: true,
        description: '请填写乘车人医院'
      }
    }
  : {
      passengers: {
        required: true,
        description: '请填写姓名'
      },
      organization: {
        required: true,
        description: '请填写医院'
      },
      department: {
        required: true,
        description: '请填写科室'
      }
    };

export const platonlineformOrder = {
  carRule: {
    required: true,
    description: '请选择用车形式'
  },
  departure_time: {
    required: false,
    expression: function(m) {
      if (m.carRule != 14 && !m.departure_time) return true;
    },
    expressionMsg: '请选择用车日期'
  },
  start_name: {
    required: true,
    description: '请选择出发地'
  },
  end_name: {
    required: false,
    expression: function(m) {
      if (m.carRule != 2 && !m.end_name) return true;
    },
    expressionMsg: '请选择目的地'
  },
  flt: {
    required: false,
    expression: function(m) {
      if ((m.carRule == 3 || m.carRule == 4) && !m.flt) return true;
    },
    expressionMsg: '请填写航班号'
  },
  // qty: {
  //   required: true,
  //   description: "请填写实际用车人数",
  //   regExp: /^\+?[1-9][0-9]*$/,
  //   regExpMsg: "请填写实际用车人数",
  //   expression: function(m) {
  //     if (m.qty > 99999999) return true;
  //   },
  //   expressionMsg: "请填写正确实际用车人数",
  // },
  passenger_name: {
    required: true,
    description: `请${tinyCookie.get('tenant') == 'roche' ? '选择' : '录入'}乘车联系人`,
    expression: function(m) {
      console.log('mmm',m);
      let name = m.passenger_name.replace(/[ ]/g, '');
      if (name.length == 0) return true;
    },
    expressionMsg: '乘车联系人不能为空'
  },
  passenger_phone: {
    required: true,
    description: '请填写联系电话',
    regExp: /^1\d{10}$/,
    regExpMsg: '请填写正确联系电话'
  },
  // 'passenger_department': {
  //     required: false,
  //     expression: function (m) {
  //         if(tinyCookie.get('tenant') == 'msd'){
  //             if(!m.passenger_department)
  //             return true
  //             let name=m.passenger_department.replace(/[ ]/g,"")
  //         if (name.length==0)
  //             return true
  //         }
  //     },
  //     expressionMsg: '请填写乘车人医院'
  // },
  // 'clat': {
  //     required: true,
  //     description: '请同意获取所在地位置'
  // },
  desc: {
    expression: function(m) {
      if (tinyCookie.get('tenant') == 'dsm' && SmartStorage.get('eventData').userEventType == 'approval' && !m.desc) return true;
    },
    expressionMsg: '请说明用车事由'
  },
  qty: {
    required: false,
    expression: function(m) {
      let reg = /^\+?[1-9][0-9]*$/;
      if (!reg.test(m.qty) && tinyCookie.get('tenant') == 'pfizer') {
        return true;
      }
    },
    expressionMsg: '请填写实际用车人数'
  }
};
